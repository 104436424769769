<template>
  <div id="stageline-navigation">
    <button
      v-show="enableSideButtons"
      class="button-navigate"
      :disabled="buttonLeftDisabled"
      aria-label="navigate left"
      @click="navigateStagesLeft()"
    >
      <fa-icon
        icon="chevron-left"
        class="fa-lg"
      />
    </button>
    <ol
      v-if="stagesLoaded"
      ref="period-container"
      class="period-container"
    >
      <li
        v-for="(stage, index) in stages"
        :key="index"
        ref="stages"
        class="stage-container"
        :class="`${iconStatus(index)}`"
      >
        <div class="stage">
          <div
            class="icon-container"
            :class="requiresActionAlert(stage.name, index)"
          >
            <hr
              class="divider"
              :class="isFirstStage(index)"
            >
            <div
              class="icon"
              :class="`${iconStatus(index)} ${completed(index) ? '' : 'clickable-icon'}`"
              @click="completed(index) ? null : navigateToStage(stage)"
            >
              <stageline-navigation-icon
                :type="stage.icon_name"
                :icon-status="iconStatus(index)"
                :requires-action="checkForActionRequiredStages(stage.name)"
              />
            </div>
            <hr
              class="divider"
              :class="isLastStage(index)"
            >
          </div>
          <p class="stage-title">
            {{ stage.title }}
          </p>
        </div>
      </li>
    </ol>
    <button
      v-show="enableSideButtons"
      class="button-navigate"
      :disabled="buttonRightDisabled"
      aria-label="navigate right"
      @click="navigateStagesRight()"
    >
      <fa-icon
        icon="chevron-right"
        class="fa-lg"
      />
    </button>
  </div>
</template>
<script>
import { mapActions, mapGetters } from 'vuex'

export default {
  name: 'StagelineNavigation',
  components: {
    StagelineNavigationIcon: () => import('@/components/StagelineV2/shared/StagelineNavigationIcon'),
  },
  data() {
    return {
      loaded: false,
      indexOfLeftBoundary: 0,
      indexOfRightBoundary: 0,
      numberOfStagesToShow: 0,
      scrollingStageIndex: 0,
      stageContainerWidth: 0,
      stageWidth: 0,
    }
  },
  computed: {
    ...mapGetters('stageline', [
      'currentPeriod',
      'currentPeriodStages',
      'currentStage',
      'currentStageIndex',
      'stageHasIncompleteConfigurationSlides',
    ]),
    stages() {
      return this.currentPeriodStages
    },
    stagesLoaded() {
      return this.stages.length > 0
    },
    buttonLeftDisabled() {
      return this.scrollingStageIndex < this.indexOfLeftBoundary
    },
    buttonRightDisabled() {
      return this.scrollingStageIndex >= this.indexOfRightBoundary
    },
    enableSideButtons() {
      return this.numberOfStagesToShow > 3
    },
  },
  watch: {
    currentStageIndex(_newIndex, _oldIndex) {
      this.getScrollingStageIndex()
      this.moveScrollBar()
    },
  },
  async mounted() {
    this.loaded = true
    this.scrollingStageIndex = this.currentStageIndex
    this.calculateMeasurementsForScrolling()
    this.getScrollingStageIndex()
    this.moveScrollBar()
  },
  methods: {
    ...mapActions('stageline', [
      'navigateToStage',
    ]),
    calculateMeasurementsForScrolling() {
      this.stageContainerWidth = this.$refs['period-container'].clientWidth || 0
      this.stageWidth = this.$refs.stages[0].clientWidth || 0
      this.numberOfStagesToShow = Math.floor(this.stageContainerWidth / this.stageWidth)

      const halfOfNumberOfStagesToShow =  Math.ceil(this.numberOfStagesToShow / 2)

      this.indexOfLeftBoundary = this.numberOfStagesToShow - halfOfNumberOfStagesToShow
      this.indexOfRightBoundary = (this.stages.length) - halfOfNumberOfStagesToShow + 1
    },
    getScrollingStageIndex() {
      if (this.currentStageIndex < this.indexOfLeftBoundary) this.scrollingStageIndex = this.indexOfLeftBoundary
      else if (this.currentStageIndex > this.indexOfRightBoundary) this.scrollingStageIndex = this.indexOfRightBoundary
      else this.scrollingStageIndex = this.currentStageIndex
    },
    moveScrollBar() {
      const scrollingLeft = this.scrollingStageIndex > this.indexOfRightBoundary
        ? this.indexOfRightBoundary * this.stageWidth
        : this.scrollingStageIndex * this.stageWidth

        const isMobile = window.innerWidth <= 599

        const scrollAdjustment = isMobile ? this.stageWidth : this.stageWidth * 2

      this.$refs['period-container'].scrollLeft = scrollingLeft - scrollAdjustment
    },
    navigateStagesLeft() {
      this.scrollingStageIndex -= 1
      this.moveScrollBar()
    },
    navigateStagesRight() {
      this.scrollingStageIndex += 1
      this.moveScrollBar()
    },
    isFirstStage(index) {
      return index === 0 ? 'hide-divider' : 'show-divider'
    },
    isLastStage(index) {
      return index === this.stages.length - 1 ? 'hide-divider' : 'show-divider'
    },
    completed(index) {
      return this.stages[index].completed_at !== null
    },
    visited(index) {
      return this.stages[index].last_visited !== null
    },
    slideCompleted(index) {
      return this.stages[index].steps.find(step => step.slides.find(slide => slide.completed_at))
    },
    iconStatus(index) {
      if (index === this.currentStageIndex)                         return 'icon-active'
      else if (this.completed(index))                               return 'icon-completed'
      else if (this.visited(index) && this.slideCompleted(index))   return 'icon-visited'
      else                                                          return 'icon-todo'
    },
    requiresActionAlert(stageName, index) {
      if (this.checkForActionRequiredStages(stageName)) {
        return index === this.currentStageIndex
          ? 'action-required'
          : 'action-required-alert'
      }

      return ''
    },
    checkForActionRequiredStages(stageName) {
      return this.stageHasIncompleteConfigurationSlides(stageName)
    },
  },
}
</script>
<style lang="scss" scoped>
$gray2: #A7A7A7;
$alert-icon: 'data:image/svg+xml,<svg xmlns="http://www.w3.org/2000/svg" width="17" height="17" viewBox="0 0 17 17" fill="none"><g clip-path="url(%23clip0_101_8)"><path d="M8.76237 0H8.23763C3.68811 0 0 3.6881 0 8.2376C0 12.7871 3.68811 16.4752 8.23763 16.4752H8.76237C13.3119 16.4752 17 12.7871 17 8.2376C17 3.6881 13.3119 0 8.76237 0Z" fill="%23F45151"/><path d="M8.5 6.73678V8.35984M8.5 9.98299H8.50354M7.80612 4.65115L4.3693 10.3887C4.29844 10.5114 4.26095 10.6505 4.26055 10.7923C4.26016 10.9339 4.29687 11.0733 4.36704 11.1964C4.43721 11.3195 4.5384 11.422 4.66052 11.4939C4.78265 11.5658 4.92147 11.6044 5.06316 11.606H11.9368C12.0786 11.6044 12.2173 11.5658 12.3395 11.4939C12.4616 11.422 12.5628 11.3195 12.633 11.1964C12.7031 11.0733 12.7399 10.9339 12.7394 10.7923C12.7391 10.6505 12.7016 10.5114 12.6307 10.3887L9.19388 4.65115C9.12156 4.5319 9.0197 4.4333 8.89815 4.36487C8.7766 4.29645 8.63947 4.2605 8.5 4.2605C8.36053 4.2605 8.2234 4.29645 8.10185 4.36487C7.9803 4.4333 7.87851 4.5319 7.80612 4.65115Z" stroke="white" stroke-width="1.14569" stroke-linecap="round" stroke-linejoin="round"/></g><defs><clipPath id="clip0_101_8"><rect width="17" height="17" fill="white"/></clipPath></defs></svg>';

@mixin flex-row {
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
}

@mixin flex-col($justify-content: center) {
  display: flex;
  flex-flow: column nowrap;
  align-items: center;
  justify-content: $justify-content;
}

div#stageline-navigation {
  @include flex-row;
  width: 85%;
  max-width: 55.438rem;
  padding-top: 1.0em;
  justify-content: center;
  margin-top: 1rem;

  button.button-navigate {
    height: 40px;
    width: 40px;
    background: #87d8d5;
    border-radius: 50px;
    padding: 1.25rem;
    font-weight: 600;
    color: $ct-ui-primary;
    border: 0;
    outline: none;

    display: flex;
    flex-flow: row nowrap;
    justify-content: center;
    align-items: center;
    margin-top: -3.875em;

    &:hover {
      color: $ct-ui-primary-dark;
      border: 0;
      outline: 0;
    }

    &:disabled {
      color: $gray2;
      background: lighten($gray2, 20%)
    }
  }

  ol.period-container {
    scroll-behavior: smooth;

    @include flex-row;
    max-width: 54.688rem;
    overflow-x: scroll;
    padding: 0 0 0.8em 0;
    -webkit-overflow-scrolling: touch;
    list-style: none;
    margin: 0 20px 1rem;

    &.disabled {
      cursor: not-allowed;
      * { pointer-events: none; }
    }

    &::-webkit-scrollbar {
      height: 4px;
    }

    &::-webkit-scrollbar-track {
      -webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0.3);
      border-radius: 5%;
    }

    &::-webkit-scrollbar-thumb {
      -webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0.5);
      border-radius: 5%;
    }

    li.stage-container {
      @include flex-row;
      flex: 0 0 auto;

      &.justify-left {
        flex-direction: row !important;
      }

      &.justify-right {
        flex-direction: row-reverse !important;
      }

      &.icon-active {
        div.stage {
          opacity: 1;
          width: 10.625rem;
        }
        div.stage div.icon-container {
          div.clickable-icon {
            transform: scale(1);
          }
        }

        div.stage p.stage-title {
          color: $ct-ui-secondary;
          font-size: 0.85rem;
        }
      }

      div.stage {
        @include flex-col($justify-content: flex-end);
        width: 9.063rem;
        height: 100%;
        min-height: 170px;
        opacity: 1;

        p.stage-title {
          width: 100%;
          height: 100%;
          min-height: 50px;
          font-size: 0.75rem;
          font-weight: 700;
          text-align: center;
          color: $ct-ui-color-4;
          background: white;
          display: flex;
          flex-flow: row wrap;
          justify-content: center;
          align-items: center;
          align-content: center;
        }

        div.icon-container {
          @include flex-row;
          width: 100%;
          justify-content: center;
          margin-bottom: 0.5em;
          position: relative;

          &.action-required::before {
            content: "Action Required";
            display: block;
            font-size: 0.75em;
            font-weight: 600;
            font-style: italic;
            text-align: center;
            background: #F45151;
            color: white;
            border-radius: 1.25em;
            padding: 0.5em 1.0em;
            position: absolute;
            top: -38px;
          }

          &.action-required-alert::before {
            content: url($alert-icon);
            display: block;
            position: absolute;
            top: 4px;
            left: 76px;
          }

          div.clickable-icon,
          div.icon-completed {
            cursor: pointer;
            transform: scale(0.65);

            &:hover {
              box-shadow: $ct-ui-box-shadow-3;
            }
          }

          ::v-deep div.icon {
            @include flex-col;
            flex-shrink: 0 !important;
            margin: 0 20px;
            border-radius: 50%;
            width: 50px;
            height: 50px;

            > circle {
              stroke-width: 6px !important;
            }

            &.icon-completed {
              background-color: $ct-ui-primary;
              stroke-opacity: 1;
            }

            &.icon-visited {
              * > circle {
                fill: $ct-ui-primary-light;
                stroke-width: 6px;
              }
            }

            &.icon-active {
              stroke-opacity: 1;
            }

            &.icon-todo {
              stroke-opacity: .3;
              filter: saturate(0);
              * > circle {
                stroke-width: 3px !important;
              }
            }
          }

          hr.divider {
            width: 100%;

            &.show-divider {
              border-color: $ct-ui-color-22;
            }

            &.hide-divider {
              border: none;
            }
          }
        }
      }
    }
  }
}

@media only screen and (max-width: 1024px) {
  div#stageline-navigation {
    width: 46.063rem;

    ol.period-container {
      &::-webkit-scrollbar {
        display: none;
      }

      li.stage-container {

        div.stage {
          width: 6.875rem;

          p.stage-title {
            font-size: 0.65em;
          }

          div.icon-container {

            &.action-required-alert::before {
              top: 2px;
              left: 60px;
            }

            div.icon {
              margin: 0 0.5em;
              cursor: pointer;
              border-radius: 50%;
              width: 50px;
              height: 50px;
            }

          }
        }

        &.icon-completed {

          .stage {
            width: 6.875rem;
          }

        }
      }
    }
  }
}

@media only screen and (max-width: 767px) {
  div#stageline-navigation {
    width: 36.375rem;

    ol.period-container {
      li.stage-container {

        div.stage{
          width: 6.25rem;

          div.icon-container {

            &.action-required-alert::before {
              top: 2px;
              left: 56px;
            }
          }
        }
      }
    }
  }
}

@media only screen and (max-width: 599px) {
  div#stageline-navigation {
    width: 100vw;
    max-width: 24.125rem;

    ol.period-container {
      li.stage-container {
        div.stage{
          width: 6.25rem;
        }

        &.icon-active {
          div.stage {
            opacity: 1;
            width: 8.125rem;
          }
        }
      }
    }
  }
}
</style>
